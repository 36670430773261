import React, { useState, useEffect } from 'react';
import {Divider, Select, Button, notification} from 'antd';
import axiosInstance from '../axiosInstance';
import {useNavigate} from 'react-router-dom'
import { getUser } from '../commonfn';
import './styles.css';

const Cart = () => {

  const [userCart, setUserCart] = useState([]);
  const userId = getUser().id;
  const navigate = useNavigate();
  // const [selectquantity, setSelectQuantity] = useState(1);
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalItems, setTotalItems] = useState(0);
  const [name,setName] = useState('');
  const [contact_details, setContact_details] = useState('');
  const [address, setAddress] = useState('');

  const renderQuantityOptions = (quantity) => {
    const options = [];
    for (let i = 1; i <= quantity; i++) {
      options.push({value: i, label: <span>{i}</span>})
    }
    return options;
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Successfully Added to Cart',
        });
  };

  let totalPrice = 0;
  let totalItems = 0;
  userCart.map((i)=>{
    totalPrice = totalPrice + i.price*i.qty;
    totalItems = totalItems + i.qty;
    console.log(totalPrice, "checking price");
    console.log(totalItems, "checking qty");
  })

  useEffect(() => {
    getCartDetails();
    console.log(userId, "userId in cart.js");
    getAddressDetails();
  },[])

  const getCartDetails = () => {
    axiosInstance
    .get(`/user_cart/${userId}`)
    .then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      let data = res.data.data;
      setUserCart(data);
      console.log(data, "data in cart")
    }).catch((err)=>console.log(err));
  }


  const setSelectQuantity = (q, newOption, e) => {
    // Add your logic here to handle the selection change
    console.log("Selected value:", q);
    console.log("Selected option:", newOption);
    console.log("idol details", e);
    axiosInstance.post(`/idol_cart_update`,{idol_id:e?.idol_id, user_id:userId, qty:q})
    .then((res)=>{
      if(res.status == 208){
        navigate('/login');
      }else if(res.status == 200){
        console.log("item updated successfully in cart")
        getCartDetails();
        openNotificationWithIcon('success')
      }
    })
  };

  const deleteItem = (e) => {
      console.log(e, "delete item");
      console.log(e.user_cart_individ_id, userId, "delete item");
      axiosInstance
      .delete(`/user_cart/${e.user_cart_individ_id}/${userId}`)
      .then((res) => {
        if (res.status == 208) {
          navigate('/login');
        }
        // let data = res.data.data;
        // setUserCart(data);
        console.log(res, "deleted from cart successfully")
        getCartDetails();
      }).catch((err)=>console.log(err));
      getCartDetails();
  }

  const getAddressDetails = () => {
    axiosInstance
    .get(`/user/get_default_address/${userId}`)
    .then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      let data = res.data.data;
      console.log(data, "address data[0] in cart")
      setName(data[0].full_name);
      setContact_details(data[0].mobile_no);
      setAddress(data[0].flat + " " + data[0].area + " " + data[0].landmark + " " + data[0].town_city + " " + data[0].state + " " + data[0].pincode + " ")
    }).catch((err)=>console.log(err));
  }

  const buy = () => {

    const order_details = {
      amount_paid: totalPrice,
      user_id : userId,
      status : "Order Placed Success",
      name:name,
      contact_details: contact_details,
      address: address,
      user_orders:userCart,
    }

    console.log(order_details, "checking userCart details");

    axiosInstance.post(`/user_cart/buy`,order_details).then((res)=>{
      if(res.status == 208){
        navigate('/login');
      }
    })

  }

  return (
    <>
        {contextHolder}
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className="col-xs-12 col-lg-8 m-2 card shadow p-2">
              <h6 style={{textAlign:'left'}}>
                Shopping Cart
              </h6>
              <Divider style={{ marginTop: '0px', marginBottom: '5px'}} />
              {userCart.map((e) => {
                return(
                  <>
                    <div className='row'>
                      <div className="col-md-4 d-flex align-items-center">
                        <img
                          src={e?.idol_image}
                          alt="sorry Image Error 😞"
                          style={{
                            width: '150px',
                            height: '150px',
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            borderRadius: '30px',
                          }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className='d-flex justify-content-between'>
                          <p>{e?.idol_name}</p>
                          <p className="fw-bold">&#8377;. {e?.price} /-</p>
                        </div>
                        <div>
                        <div className="d-flex align-items-center">
                            <div className='mx-2'>quantity</div>
                            <Select
                              defaultValue={e?.qty}
                              style={{
                                width: 50,
                                borderRadius: 10,
                              }}
                              options = {renderQuantityOptions(e?.stock)}
                              onSelect={(v,o)=>setSelectQuantity(v, o, e)}
                              // onChange={(newValue, newOption) => handleSelectChange(newValue, newOption)}
                            />
                            <a href="#" className="mx-2" onClick={() => deleteItem(e)}>Delete</a>
                            </div>
                        </div>
                      </div>
                    </div>
                    <Divider style={{ marginTop: '5px', marginBottom: '5px'}} />
                  </>
                )
              })}
          </div>
          <div className="col-xs-12 col-lg-3 m-2 card shadow p-2" style={{height:"200px"}}>
            <h6 style={{textAlign:'left'}}>
              Order Summary
            </h6>
            <Divider style={{ marginTop: '0px', marginBottom: '5px'}} />
            <div>Subtotal ({totalItems} items): <span className='fw-bold'>&#8377; {totalPrice}</span></div>
            <Button type="primary" onClick={buy}>Proceed to Buy</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cart