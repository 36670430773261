import React, { useEffect, useState } from 'react';
import main_logo from '../assets/main_logo.jpeg';
import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

export default function MarriagesPage() {
  const [catList, setCatList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCat();
  }, []);
  const getCat = () => {
    axiosInstance.get('/idol_cat/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      console.log(res);
      let data = res.data.data;
      console.log(data);
      setCatList(data);
    });
  };
  return (
    <div className="container">
      <div>
        <img
          src={main_logo}
          height={200}
          width={300}
          className=""
          style={{
            borderRadius: '8px',
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.21)',
          }}
        />
      </div>
      <div className="container mt-5 ">
        <h3 className="fw-bold mb-4">Shop by Category</h3>
        <Row
          gutter={{
            xs: 8,
            sm: 8,
            md: 16,
            lg: 16,
          }}
        >
          {catList.map((e) => {
            return (
              <>
                <Col className="gutter-row" span={8}>
                  <Card
                    className="mb-3"
                    style={{ borderRadius: 8 }}
                    hoverable
                    onClick={() => navigate(`/idol-list/${e.id}`)}
                    cover={
                      <img
                        alt="sorry Image Error 😞"
                        height="200px"
                        className="p-2"
                        style={{
                          width: '250px',
                          textAlign: 'center',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        src={e.cat_image}
                      />
                    }
                  >
                    <Card.Meta title={e.cat_name} />
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
