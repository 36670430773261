import React from 'react';
import { Form, Input, Button } from 'antd';
import './styles.css'
import axiosInstance from '../axiosInstance';
import { NavLink, useNavigate } from 'react-router-dom';


const Signup = (props) => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log('Received values:', values);
    axiosInstance.post("/user/register",values).then((res)=>{
      props.openRegisterNotification()
      navigate('/login')
    }).catch((err)=>console.log(err))
  };

  const formRef = React.createRef();
  const compareToFirstPassword = (rule, value, callback) => {
    const form = formRef.current;
    if (value && value !== form.getFieldValue('password')) {
      callback('The two passwords do not match!');
    } else {
      callback();
    }
  };


  return (
  <div className='w-25'>
        <h1 className='login-header'>Register</h1>
    <Form
    ref={formRef}
      name="signup-form"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
    >
      <Form.Item
        label="Full Name"
        name="name"
        rules={[{ required: true, message: 'Please input your full name!' }]}
      >
        <Input  className='login-input-box' />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input  className='login-input-box'/>
      </Form.Item>
      <Form.Item
        label="Mobile Number"
        name="mobile"
        rules={[
          { required: true, message: 'Please input your mobile number!' },
          { pattern: /^[0-9]{10}$/, message: 'Please enter a valid 10-digit mobile number!' },
        ]}
      >
        <Input  className='login-input-box'/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password  className='login-input-box'/>
      </Form.Item>
  <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password!' },
          { validator: compareToFirstPassword },
        ]}
      >
        <Input.Password  className='login-input-box'/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className='login-btn'>
          Signup
        </Button>
      </Form.Item>
    </Form>
    <div>
    Do you have an account? <NavLink to="/login"  className='signup-link'>Login Now</NavLink>
    </div>
    </div>
  );
};

export default Signup;
