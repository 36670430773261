import { LogoutOutlined, PhoneFilled, UserOutlined, ShoppingCartOutlined, LoginOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser, userLogout } from '../commonfn';

export default function HeaderSec() {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = ()=>{
    userLogout()
    navigate('/login')
  };
  const [user, setUser] = useState(false)

  useEffect(() => {
    let userData = getUser()
    if(userData && userData.exp && userData.email && userData.id){
      setUser(true)
    }else{
      setUser(false)
    }
    
  }, [])
  

  return (
    <div className='d-flex justify-content-between'>
      <div>
      <PhoneFilled />  +0 000-000-0000    </div>
      <div className='d-flex align-items-center nav-btn' >
      <Button type="primary" className={location.pathname=='/poojas'?'active-btn':''} onClick={()=>navigate('/poojas')}>Poojas</Button>
      <Button type="primary" className={location.pathname=='/idols'?'active-btn':''} onClick={()=>navigate('/idols')}>Idols</Button>
      {user?<><Button type="primary" className={location.pathname=='/user-info'?'active-btn':''} onClick={()=>navigate('/user-info')} icon={<UserOutlined />}>User Info</Button>
      <Button type="primary" className={location.pathname=='/cart'?'active-btn':''} onClick={()=>navigate('/cart')} icon={<ShoppingCartOutlined />}>Cart</Button>
      <Button type="primary" icon={<LogoutOutlined />} onClick={logout}>Logout</Button></>
      :<Button type="primary" icon={<LoginOutlined />} onClick={()=>navigate('/login')}>Login</Button>}
      
      </div>
    </div>
  )
}
