import React, { useEffect, useState } from 'react';
import HeaderSec from './HeaderSec';
import FooterSec from './FooterSec';
import { getUser } from '../commonfn';
import main_logo from '../assets/main_logo.jpeg';
import { Button, Select, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

export default function PoojasPage() {
  const user = getUser();
  const navigate = useNavigate();
  const [cityList, setCityList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('Chennai');
  const [selectedLanguage, setSelectedLanguage] = useState('Tamil');
  useEffect(() => {
    getCityList();
    getLangList();
  }, []);
  const getCityList = () => {
    axiosInstance.get('/city/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      let data = res.data.data.map((e) => {
        return { value: e.id, label: e.city_name };
      });
      setCityList(data);
    });
  };
  const getLangList = () => {
    axiosInstance.get('/lang/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      console.log(res);
      let data = res.data.data.map((e) => {
        return { value: e.id, label: e.lang };
      });
      setLangList(data);
    });
  };

  return (
    <div className="container">
      <div>
        <img
          src={main_logo}
          height={200}
          width={300}
          className=""
          style={{
            borderRadius: '8px',
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.21)',
          }}
        />
      </div>
      <div>
        <h1>
          Welcome <span style={{ color: '#bc222e' }}>{user?user.name:""}</span>!
        </h1>
      </div>
      <div>
        <div className="select-wraper">
          {' '}
          <Space wrap align="end">
            <div style={{ lineHeight: '35px' }}>
              <div className="select-label-key">To Perform Pooja in</div>
              <Select
                defaultValue="Chennai"
                style={{
                  width: 290,
                  borderRadius: 10,
                }}
                options={cityList}
                onSelect={(v, o) => setSelectedLocation(o.label)}
              />
            </div>
            <div style={{ lineHeight: '35px' }}>
              <div className="select-label-key">My Priest Preference</div>
              <Select
                defaultValue="Tamil"
                style={{
                  width: 290,
                  borderRadius: 10,
                }}
                options={langList}
                onSelect={(v, o) => setSelectedLanguage(o.label)}
              />
            </div>
            <div style={{ lineHeight: '35px' }} className="nav-btn">
              {' '}
              <Button
                type="primary"
                onClick={() => {
                  navigate('/poojas-list');
                  localStorage.setItem(
                    'pooja',
                    JSON.stringify({ selectedLanguage, selectedLocation })
                  );
                }}
              >
                View all services <RightOutlined />
              </Button>
            </div>
          </Space>
        </div>
      </div>
    </div>
  );
}
