import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { getUser } from '../../commonfn';
import axiosInstance from '../../axiosInstance';
import {Modal, notification, Divider} from 'antd';
import {useNavigate} from 'react-router-dom';
import { Form, Input, Button, Checkbox } from 'antd';

const YourAddresses = () => {
  const [address, setAddress] = useState([]);
  const userId = getUser().id;
  const navigate = useNavigate();
 
  useEffect(() => {
    getAddress();
  }, []);
  
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Address Addded Successfully',
        });
  };

  const getAddress = () => {
    axiosInstance
    .get(`/user/get_address/${userId}`)
    .then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      let data = res.data.data;
      setAddress(data);
      console.log(data);
    }).catch((err)=>console.log(err));
  }

  const addAddress = (values) => {
    let address = {...values, userId}
    if(values.default_address == undefined){
      address = {...values, userId, default_address:false}
      console.log(address, "testingundefined");
    }
    console.log(address, "add testing");
    axiosInstance.post("/user/add_address",address).then((res)=>{
      if(res.status == 208){
        navigate('/login');
      }else if(res.status == 200){
        setIsModalOpen(false);
        openNotificationWithIcon('success');
        getAddress();
      }
    }).catch((err)=>console.log(err))
  }

  const editAddress = (values) => {
    console.log(values, "edit testing");
  }

  const deleteAddress = (values) => {
    console.log(values, "delete testing");
  }

  //add address modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //edit address modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDataIndex, setSelectedDataIndex] = useState(null);
  const showEditModal= (i) => {
    setIsEditModalOpen(true);
    setSelectedDataIndex(i);
    console.log(i, "individual objects");
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
    setSelectedDataIndex(null);
  };


  return (
    <>
    {contextHolder}
      <div className="d-flex flex-wrap" style={{}}>
        <div className="d-flex justify-content-center align-items-center m-2" style={{width:"275px", minHeight:"200px", border:"3px dotted grey", borderRadius:"10px", cursor:"pointer"}} onClick={showModal}>
          <div className='d-flex flex-column justify-content-center align-items-center'>
          <PlusOutlined style={{fontSize:"30px"}}/>
          Add Address
          </div>
        </div>
        {address.map((i)=>{
          return(
            <div className='text-start p-3 m-2' style={{width:"275px", minHeight:"200px", border:i.default_address? "3px solid #bc222ea3":"1px solid grey", borderRadius:"10px"}} key = {i.id}>
              <div style={{height:"22px"}}>{i.default_address ? (<div className="fw-bold">Default Address</div>):(<div></div>)}</div>
              <div>{i.full_name}</div>
              <div>{i.flat}</div>
              <div>{i.area},{i.landmark}</div>
              <div>{i.town_city},{i.state},{i.pincode}</div>
              <div>Phone number: {i.mobile_no}</div>
              <Divider style={{ marginTop: '5px', marginBottom: '7px' }} />
                <div className='d-flex justify-content-end'>
                  <Button type='primary' className='login-btn mx-1' onClick={()=>showEditModal(i)}>Edit</Button>
                  <Button type='primary' className='login-btn mx-1' onClick = {deleteAddress}>Delete</Button>
                </div>
            </div>
          )
        })}

      </div>

      <Modal title="Add Address" open={isModalOpen} onCancel={handleCancel} footer={null}>
        <Form
          name="login-form"
          onFinish={addAddress}
          initialValues={{ remember: true }}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label="Full name (First and Last name"
            name="full_name"
            rules={[{ required: true, message: 'Please input your Full Name!' }]}
          >
            <Input className='login-input-box'/>
          </Form.Item>

          <Form.Item
            label="Mobile number"
            name="mobile_number"
            rules={[{ required: true, message: 'Please input your Mobile number!' }]}
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[{ required: true, message: 'Please input your Pincode' }]}
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Flat, House no., Building, Company, Apartment"
            name="flat"
            rules={[{ required: true, message: 'Please input your Flat, House no.' }]}
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Area, Street, Sector, Village"
            name="area"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Landmark"
            name="landmark"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Town/City"
            name="town_city"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="State"
            name="state"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
          name="default_address"
          valuePropName="checked"
          >
            <Checkbox>Default Address</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className='login-btn'>
              Add Address
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="Edit Address" open={isEditModalOpen} onCancel={handleEditCancel} footer={null}>
        <Form
          name="login-form"
          onFinish={editAddress}
        initialValues={{ full_name:selectedDataIndex?.full_name,mobile_number:selectedDataIndex?.mobile_no, pincode:selectedDataIndex?.pincode, flat:selectedDataIndex?.flat, area:selectedDataIndex?.area,landmark:selectedDataIndex?.landmark,town_city:selectedDataIndex?.town_city,state:selectedDataIndex?.state,default_address:selectedDataIndex?.default_address}}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label="Full name (First and Last name"
            name="full_name"
            rules={[{ required: true, message: 'Please input your Full Name!' }]}
          >
            <Input className='login-input-box'/>
          </Form.Item>

          <Form.Item
            label="Mobile number"
            name="mobile_number"
            rules={[{ required: true, pattern: /^[0-9]{10}$/, message: 'Please input your Mobile number!' }]}
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[{ required: true, message: 'Please input your Pincode' }]}
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Flat, House no., Building, Company, Apartment"
            name="flat"
            rules={[{ required: true, message: 'Please input your Flat, House no.' }]}
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Area, Street, Sector, Village"
            name="area"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Landmark"
            name="landmark"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="Town/City"
            name="town_city"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
            label="State"
            name="state"
          >
            <Input className='login-input-box' />
          </Form.Item>

          <Form.Item
          name="default_address"
          valuePropName="checked"
          >
            <Checkbox>Default Address</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className='login-btn'>
              Modify Address
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>

  )
}

export default YourAddresses
