import React from 'react';
import { Form, Input, Button } from 'antd';
import './styles.css'
import { NavLink, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import { getUser } from '../commonfn';
const Login = (props) => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log('Received values:', values);
    axiosInstance.post('/user/login', values).then((res)=>{
      if(res.data.token){
        localStorage.setItem("token",`jwt ${res.data.token}`)
        navigate('/poojas')
        props.openLoginNotification(true)
      }
    }).catch((err)=>{
      console.log(err)
      props.openLoginNotification(false,err.response.data.message)
    })
  };

  return (
    <>
    <div className='w-25'>
        <h1 className='login-header'>Login</h1>
    <Form
      name="login-form"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        label="Username (Email or Mobile number)"
        name="username"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input className='login-input-box'/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password className='login-input-box' />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className='login-btn'>
          Login
        </Button>
      </Form.Item>
    </Form>

    <div>
    New to our site? <NavLink to="/register"  className='signup-link'>Sign up</NavLink> here
    </div>
    </div>
    </>
  );
};

export default Login;
