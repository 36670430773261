import { Button } from 'antd'
import React from 'react'

const IdolOrders = () => {
  return (
    <>
        <div className='card shadow p-2 mt-2'>
            <div className='d-flex justify-content-between'>
                <table className='mb-2'>
                <thead >
                    <tr>
                    <th style={{ width:"200px"}}>Order Placed</th>
                    <th style={{ width:"200px"}}>Total</th>
                    <th style={{ width:"200px"}}>Ship To</th>
                    <th style={{ width:"200px"}}>Order</th>
                    </tr>
                </thead>
                <tbody >
                    <tr>
                    <td> 29 May 2023</td>
                    <td>₹515.00</td>
                    <td>KUMAR</td>
                    <td># 890-6787898-5677657</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-start'>
                <div >
                    <img
                        alt="sorry Image Error 😞"
                        height="200px"
                        className="p-2"
                        style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: '30px',
                        }}
                        src="https://m.media-amazon.com/images/I/81RD674ZphL._AC_UF894,1000_QL80_.jpg"
                    />
                </div>
                <div>
                <p>
                    Vishnu Idol
                </p>
                <Button type="primary">Buy Again</Button>
                </div>
            </div>
        </div>
        <div className='card shadow p-2 mt-2'>
            <div className='d-flex justify-content-between'>
                {/* <p>
                    ORDER PLACED <span>19 JUN 2023</span>
                </p>
                <p>
                    TOTAL <span>&#8377;1015.00</span>
                </p>
                <p>
                    SHIP TO ARUN KUMAR
                </p>
                <p>ORDER <span># 171-8245084-9381943</span></p> */}
                <table className='mb-2'>
                <thead >
                    <tr>
                    <th style={{ width:"200px"}}>Order Placed</th>
                    <th style={{ width:"200px"}}>Total</th>
                    <th style={{ width:"200px"}}>Ship To</th>
                    <th style={{ width:"200px"}}>Order</th>
                    </tr>
                </thead>
                <tbody >
                    <tr>
                    <td> 19 June 2022</td>
                    <td>₹1,015.00</td>
                    <td>TRARUN KUMAR</td>
                    <td># 999-8956765-7898567</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-start'>
            <div >
                    <img
                        alt="sorry Image Error 😞"
                        height="200px"
                        className="p-2"
                        style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: '30px',
                        }}
                        src="https://m.media-amazon.com/images/I/71wWUCJTaoL._AC_UF894,1000_QL80_.jpg"
                    />
                </div>
                <div>
                <p>
                    Shiva Idol
                </p>
                <Button type="primary">Buy Again</Button>
                </div>
            </div>
            <div className='d-flex justify-content-start'>
            <div >
                    <img
                        alt="sorry Image Error 😞"
                        height="200px"
                        className="p-2"
                        style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: '30px',
                        }}
                        src="https://m.media-amazon.com/images/I/81YyFGdU8kL.jpg"
                    />
                </div>
                <div>
                <p>
                    Ganesha Idol
                </p>
                <Button type="primary">Buy Again</Button>
                </div>
            </div>
        </div>
    </>
  )
}

export default IdolOrders
