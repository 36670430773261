import React from 'react';
import LoginPage from './main/components/LoginPage';
import SignupPage from './main/components/SignupPage';
import bg_image from './main/assets/bg-image.jpg';
import 'antd/dist/antd.css';
import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import ErrorPage from './main/components/ErrorPage';
import { getUser } from './main/commonfn';
import PoojasPage from './main/layout/PoojasPage';
import MainLayout from './main/layout/MainLayout';
import MarriagesPage from './main/layout/MarriagesPage';
import PoojasList from './main/components/PoojasList';
import PoojasDetail from './main/components/PoojasDetail';
import { notification } from 'antd';
import IdolList from './main/components/IdolList';
import IdolDetail from './main/components/IdolDetail';
import Cart from './main/components/Cart';
import UserInfo from './main/components/UserInfo/UserInfo';

const App = () => {
  const [api, contextHolder] = notification.useNotification();

  const containerStyle = {
    backgroundImage: `url(${bg_image})`, // Change this to the path of your background image
    backgroundSize: 'cover',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const userLoader = async () => {
    const user = await getUser();
    if (!user) {
      return redirect('/login');
    }
    return null;
  };
  const openLoginNotification = (login, errMessage) => {
    if (login) {
      const user = getUser();
      api['success']({
        message: `Logged successfully`,
        description: `Hello, ${user.name}!`,
        duration: 3,
      });
    } else {
      api['error']({
        message: `Login failed`,
        description: `${errMessage}`,
        duration: 3,
      });
    }
  };
  const openRegisterNotification = () => {
    api['success']({
      message: `Registered successfully`,
      description: `Make your login here! 😊`,
      duration: 3,
    });
  };
  const router = createBrowserRouter([
    {
      errorElement: <ErrorPage />,

      children: [
        {
          path: '/',
          errorElement: <ErrorPage />,
          element: <Navigate to="/poojas" />,
        },
        {
          path: '/login',
          element: <LoginPage openLoginNotification={openLoginNotification} />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/register',
          element: (
            <SignupPage openRegisterNotification={openRegisterNotification} />
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: '/',
          element: <MainLayout />,
          errorElement: <ErrorPage />,
          children: [
            // {
            //   path:"/",
            //   errorElement: <ErrorPage />,
            //   element: <Navigate to="/app/poojas" />,
            // },
            {
              path: '/poojas',
              element: <PoojasPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/idols',
              element: <MarriagesPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/poojas-list',
              element: <PoojasList />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/poojas-details',
              element: <PoojasDetail />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/idol-list/:id',
              element: <IdolList />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/idol-details/:idolcat/:id',
              element: <IdolDetail />,
              errorElement: <ErrorPage />,
            },
            {
              path: '/cart',
              element: <Cart />,
              errorElement: <ErrorPage />,
              loader: () => userLoader(),
            },
            {
              path: '/user-info',
              element: <UserInfo />,
              errorElement: <ErrorPage />,
              loader: () => userLoader(),

            },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      {contextHolder}
      <div style={containerStyle}>
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
