import React from 'react'
import { Tabs } from 'antd';
import '../styles.css'
import LoginSecurity from './LoginSecurity';
import YourAddresses from './YourAddresses';
import IdolOrders from './IdolOrders';
import PoojaOrders from './PoojaOrders';

const UserInfo = () => {

  const items = [
    {
      key: '1',
      label: 'Login & Security',
      children: <LoginSecurity/>,
    },
    {
      key: '2',
      label: 'Your Addresses',
      children: <YourAddresses/>,
    },
    {
      key: '3',
      label: 'Idol Orders',
      children: <IdolOrders/>,
    },
    {
      key: '4',
      label: 'Pooja Orders',
      children: <PoojaOrders/>,
    },
  ];

return(
  <div className="card shadow p-5">
      <Tabs defaultActiveKey="1" items={items}/>
  </div>
)
}

export default UserInfo