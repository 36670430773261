import React, { useEffect, useState } from 'react';
import HeaderSec from '../layout/HeaderSec';
import FooterSec from '../layout/FooterSec';
import card_img_01 from '../assets/card_img_01.jpg';
import './styles.css';

import {
  Layout,
  Flex,
  Divider,
  Menu,
  Space,
  Select,
  Button,
  Card,
  Row,
  Col,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Search from 'antd/lib/transfer/search';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import PoojaOrders from './UserInfo/PoojaOrders';

const { Header, Footer, Content, Sider } = Layout;

const headerStyle = {
  textAlign: 'center',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
};

// const contentStyle = {
//   textAlign: 'center',
//   minHeight: 120,
//   lineHeight: '120px',
// height:'80vh',
// borderRadius: 8,
// backgroundColor:'white',
// marginLeft:5
// };

const footerStyle = {
  textAlign: 'center',
};

const layoutStyle = {
  overflow: 'auto',
  width: '100vw',
  //   width: 'calc(100% - 8px)',
  //   maxWidth: 'calc(100% - 8px)',
  height: '100%',
};
const siderStyle = {
  paddingTop: 18,
  padding: 8,
};

export default function PoojasList() {
  const navigate = useNavigate();
  const [catList, setCatList] = useState([]);
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const [cityList, setCityList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [poojasList, setPoojasList] = useState([]);

  useEffect(() => {
    getCat();
    getCityList();
    getLangList();
    getPoojasList();
    const location = JSON.parse(localStorage.getItem('pooja'));
    setSelectedLocation(location.selectedLocation);
    setSelectedLanguage(location.selectedLanguage);
  }, []);

  const onOpenChange = (keys) => {};
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }

  const getCat = () => {
    axiosInstance.get('/cat/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      console.log(res);
      let data = res.data.data.map((e) => {
        return getItem(e.cat_name, e.id, []);
      });
      console.log(data);
      setCatList(data);
    });
  };

  const getCityList = () => {
    axiosInstance.get('/city/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      let data = res.data.data.map((e) => {
        return { value: e.id, label: e.city_name };
      });
      setCityList(data);
    });
  };

  const getLangList = () => {
    axiosInstance.get('/lang/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      console.log(res);
      let data = res.data.data.map((e) => {
        return { value: e.id, label: e.lang };
      });
      setLangList(data);
    });
  };

  const getPoojasList = () => {
    axiosInstance.get('/services/list').then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      console.log(res.data.data, "checking poojas list");
      setPoojasList(res.data.data)
    });
  }


  return (
    <>
      <div style={{ lineHeight: '35px' }}>
        {' '}
        <div className="select-wraper">
          {' '}
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex align-items-end">
              <div className="me-3" style={{ lineHeight: '35px' }}>
                <div className="select-label-key">To Perform Pooja in</div>
                <Select
                  value={selectedLocation}
                  style={{
                    width: 200,
                    borderRadius: 10,
                  }}
                  options={cityList}
                  onSelect={(v, o) => setSelectedLocation(o.label)}
                />
              </div>
              <div style={{ lineHeight: '35px' }}>
                <div className="select-label-key">My Priest Preference</div>
                <Select
                  value={selectedLanguage}
                  style={{
                    width: 200,
                    borderRadius: 10,
                  }}
                  options={langList}
                  onSelect={(v, o) => setSelectedLanguage(o.label)}
                />
              </div>
              <div style={{ lineHeight: '35px' }} className="nav-btn">
                <Button
                  type="primary"
                  onClick={() => {
                    localStorage.setItem(
                      'pooja',
                      JSON.stringify({ selectedLanguage, selectedLocation })
                    );
                  }}
                >
                  View all services
                  <RightOutlined />
                </Button>
              </div>
            </div>
            <div style={{ lineHeight: '35px' }}>
              <Search placeholder="Search" />
            </div>
          </div>
        </div>
      </div>
      <Divider
        style={{ margin: '10px 0px', borderTopColor: 'rgb(0,0,0,0.3)' }}
      />

      <div>
        <Layout>
          <Sider width="25%" style={siderStyle}>
            <h5 style={{ marginBottom: 5 }}>
              {JSON.parse(localStorage.getItem('pooja')).selectedLocation} Pooja Services
            </h5>
            <Divider
              style={{ margin: '10px 0px', borderTopColor: 'rgb(0,0,0,0.3)' }}
            />
            <Menu
              mode="inline"
              defaultOpenKeys={'1'}
              onOpenChange={onOpenChange}
              style={{
                width: 256,
              }}
              items={catList}
            />
          </Sider>
          <Content style={{ marginLeft: 16 }}>
            <Row
              gutter={{
                xs: 8,
                sm: 8,
                md: 16,
                lg: 16,
              }}
            >
              {poojasList.map((i, index)=>{
                return(
                  <Col className="gutter-row" span={8} key={index}>
                    <Card
                      className="mb-3"
                      style={{ borderRadius: 8 }}
                      hoverable
                      onClick={() => navigate('/poojas-details', {state: {details: poojasList[index]}})}
                      cover={
                        <img
                          alt="example"
                          height="200px"
                          style={{
                            width: '250px',
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop:'10px'
                          }}
                          src={i.image}
                        />
                      }
                    >
                      <Card.Meta title={i.service_name} />
                    </Card>
                  </Col>
                )
              })}

              {/* <Col className="gutter-row" span={8}>
                <Card
                  className="mb-3"
                  style={{ borderRadius: 8 }}
                  hoverable
                  onClick={() => navigate('/poojas-details/2')}
                  cover={
                    <img
                      alt="example"
                      height="200px"
                      style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={card_img_01}
                    />
                  }
                >
                  <Card.Meta title="Aksharabhyasam" description="description" />
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  className="mb-3"
                  style={{ borderRadius: 8 }}
                  hoverable
                  onClick={() => navigate('/poojas-details/3')}
                  cover={
                    <img
                      alt="example"
                      height="200px"
                      style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={card_img_01}
                    />
                  }
                >
                  <Card.Meta title="Aksharabhyasam" description="description" />
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  className="mb-3"
                  style={{ borderRadius: 8 }}
                  hoverable
                  onClick={() => navigate('/poojas-details/4')}
                  cover={
                    <img
                      alt="example"
                      height="200px"
                      style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={card_img_01}
                    />
                  }
                >
                  <Card.Meta title="Aksharabhyasam" description="description" />
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  className="mb-3"
                  style={{ borderRadius: 8 }}
                  hoverable
                  onClick={() => navigate('/poojas-details/5')}
                  cover={
                    <img
                      alt="example"
                      height="200px"
                      style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={card_img_01}
                    />
                  }
                >
                  <Card.Meta title="Aksharabhyasam" description="description" />
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  className="mb-3"
                  style={{ borderRadius: 8 }}
                  hoverable
                  onClick={() => navigate('/poojas-details/6')}
                  cover={
                    <img
                      alt="example"
                      height="200px"
                      style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={card_img_01}
                    />
                  }
                >
                  <Card.Meta title="Aksharabhyasam" description="description" />
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  className="mb-3"
                  style={{ borderRadius: 8 }}
                  hoverable
                  onClick={() => navigate('/poojas-details/7')}
                  cover={
                    <img
                      alt="example"
                      height="200px"
                      style={{
                        width: '250px',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={card_img_01}
                    />
                  }
                >
                  <Card.Meta title="Aksharabhyasam" description="description" />
                </Card>
              </Col> */}
            </Row>
          </Content>
        </Layout>
      </div>
    </>
  );
}
