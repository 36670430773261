import { Button } from 'antd';
import React from 'react';
import { NavLink, useNavigate, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  const navigate = useNavigate();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <h2>404</h2>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      Please Go to <NavLink to="/"  className='signup-link'>Home</NavLink> Page 
      <div>
      <Button type="primary" htmlType="submit" className='login-btn' onClick={()=>navigate('/')}>
          Home
        </Button>
      </div>
    </div>
  );
}

export default ErrorPage