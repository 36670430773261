import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Empty, Row } from 'antd';
import { Button, Select, Space } from 'antd';
import { Slider, Switch } from 'antd';

export default function IdolList() {
  const navigate = useNavigate();
  const params = useParams();
  const [catName, setCatName] = useState('');
  const [idolData, setIdolData] = useState([]);

  useEffect(() => {
    getCatName();
    getIdolList();
  }, []);

  const getCatName = () => {
    axiosInstance.post('/idol_cat/by_id', { cat_id: params.id }).then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      console.log(res);
      let data = res.data.data;
      setCatName(data[0].cat_name);
    }).catch((err)=>console.log(err));
  };

  const getIdolList = () => {
    axiosInstance
      .post('/idol_list/by_cat_id', { cat_id: params.id })
      .then((res) => {
        if (res.status == 208) {
          navigate('/login');
        }
        console.log(res);
        let data = res.data.data;
        setIdolData(data);
      }).catch((err)=>console.log(err));
  };

  const sortByPrice = (v) => {
    console.log(v, 'sort by price');
    if (v == 1) {
      const sortedData = [...idolData].sort((a, b) => a.price - b.price);
      setIdolData(sortedData);
    } else if (v == 0) {
      const sortedData = [...idolData].sort((a, b) => b.price - a.price);
      setIdolData(sortedData);
    } else {
      getIdolList();
    }
  };

  const filterByPrice = (e) => {
    console.log(e[0], e[1]);
    const filteredData = [...idolData].filter(
      (value) => value.price >= e[0] && value.price <= e[1]
    );
    console.log(filteredData, 'filterbyprice');
  };

  return (
    <div className="container bg-white p-3">
      <div>
        <h3 className="fw-bold">{catName} List</h3>
      </div>
      <div className="container border mb-3 p-2" style={{ lineHeight: '35px' }}>
        <div className="select-wraper d-flex">
          <Space wrap align="start">
            <div className="d-flex mx-3">
              <div className="select-label-key mx-1">Sort By</div>
              <Select
                defaultValue="Relevance"
                style={{
                  width: 150,
                  borderRadius: 10,
                }}
                options={[
                  { value: 2, label: <span>Relevance</span> },
                  { value: 1, label: <span>Price - Low to High</span> },
                  { value: 0, label: <span>Price - High to Low</span> },
                ]}
                onSelect={(v, o) => sortByPrice(v)}
              />
            </div>
            <div className="d-flex mx-3">
              <div className="select-label-key mx-1">Filter By</div>
              <Slider
                defaultValue={[1000, 4000]}
                min={500}
                max={10000}
                range={{
                  draggableTrack: true,
                }}
                style={{
                  width: 150,
                }}
                onChange={(e) => filterByPrice(e)}
              />
            </div>
          </Space>
        </div>
      </div>
      <div>
        {idolData.length > 0 ? (
          <Row
            gutter={{
              xs: 8,
              sm: 8,
              md: 16,
              lg: 16,
            }}
          >
            {idolData.map((e) => {
              return (
                <>
                  <Col className="gutter-row" span={8}>
                    <Card
                      className="mb-3"
                      style={{ borderRadius: 8 }}
                      hoverable
                      onClick={() =>
                        navigate(`/idol-details/${e.idol_cat}/${e.id}`)
                      }
                      cover={
                        <img
                          alt="sorry Image Error 😞"
                          height="200px"
                          className="p-2"
                          style={{
                            width: '250px',
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            borderRadius: '30px',
                          }}
                          src={e.idol_image}
                        />
                      }
                    >
                      <Card.Meta
                        title={e.idol_name}
                        description={`Rs. ${e.price}/-`}
                      />
                    </Card>
                  </Col>
                </>
              );
            })}
          </Row>
        ) : (
          <div className="p-5">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
}
