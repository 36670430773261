import React, { useEffect, useState } from 'react';
import {Form, Input, Button, Checkbox} from 'antd';
import axiosInstance from '../../axiosInstance';
import { getUser } from '../../commonfn';
import { userLogout } from '../../commonfn';
import { useNavigate } from 'react-router-dom';


const LoginSecurity = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNum, setMobileNum] = useState('');
    const userDetails = getUser();
    const navigate = useNavigate();

    console.log(userDetails, userDetails.name, userDetails.email, userDetails.mobile, "chceking userId in loginSecurity")
    const onFinish = (values) => {
        console.log("success:", values);
        axiosInstance.post('/user/edituser',
            {
                name:values.name,
                mobile:values.mobile,
                email:values.email,
                id:userDetails?.id
            }
        ).then((res)=>{
            console.log(res,"checking response")
            if(res.status == 200){
                userLogout();
                navigate('/login')
            }
        }).catch((err)=>console.log(err,"err"))
    }
    const [componentDisabled, setComponentDisabled] = useState(false);
 
  return (
    <div className='w-25'>
        <div style={{display:"flex", flexDirection:"row"}}> 
        <Checkbox
        checked={componentDisabled}
        onChange={(e) => setComponentDisabled(e.target.checked)}
      >
        Edit Details
      </Checkbox>
        </div>
        <Form
        name="signup-form"
        onFinish={onFinish}
        disabled={!componentDisabled}
        initialValues={{ name:userDetails.name, email:userDetails.email, mobile:userDetails.mobile }}
        layout="vertical"
        >
        <Form.Item
            label="Full Name"
            name="name"
        >
            <Input  className='login-input-box' />
        </Form.Item>

        <Form.Item
            label="Email"
            name="email"
        >
            <Input  className='login-input-box'/>
        </Form.Item>
        <Form.Item
            label="Mobile Number"
            name="mobile"
            rules={[
            { pattern: /^[0-9]{10}$/, message: 'Please enter a valid 10-digit mobile number!' },
            ]}
        >
            <Input  className='login-input-box'/>
        </Form.Item>
        <Form.Item style={{display:"flex", flexDirection:"row"}}>
            <Button type="primary" htmlType="submit" className='login-btn'>
            Save
            </Button>
        </Form.Item>
        </Form>
    </div>
  )
}

export default LoginSecurity