import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, notification ,Select, Button, Rate, Space} from 'antd';
import { getUser } from '../commonfn';

export default function IdolDetail() {
  
  const navigate = useNavigate();
  const params = useParams();
  const [idolData, setIdolData] = useState({});
  const [idolDimensions, setIdolDimensions] = useState({});
  const [selectquantity, setSelectQuantity] = useState(1);
  const [userCart, setUserCart] = useState([]);
  const userId = getUser()?getUser().id:null;

  const options = [];
  for (let i=1; i<=idolData?.stock; i++){
    options.push({value: i, label: <span>{i}</span>})
  }

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Successfully Added to Cart',
        });
  };
 
  useEffect(() => {
    getIdolListDetails();
    if(getUser()){
      getCartDetails();
    }
  }, []);

  const getIdolListDetails = () => {
    axiosInstance
      .post('/idol_list/by_cat_id_and_idol_id', {
        cat_id: params.idolcat,
        id: params.id,
      })
      .then((res) => {
        if (res.status == 208) {
          navigate('/login');
        }
        let data = res.data.data;
        // console.log(data,"idolData")
        setIdolData(data[0]);
        setIdolDimensions(JSON.parse(data[0]?.idol_detail));
      }).catch((err)=>console.log(err));
  };

  const getCartDetails = () => {
    axiosInstance
    .get(`/idol_cart/${userId}`)
    .then((res) => {
      if (res.status == 208) {
        navigate('/login');
      }
      let data = res.data.data;
      setUserCart(data);
      console.log(data, "data in cart")
    }).catch((err)=>console.log(err));
  }

  const addToCart = () => {
    const obj = userCart.find((i)=>i.idol_id == idolData?.id);
    if(obj){
      console.log("item updated")
          axiosInstance.post(`/idol_cart_update`,{idol_id:idolData?.id, user_id:userId, qty:selectquantity})
          .then((res)=>{
            if(res.status == 208){
              navigate('/login');
            }else if(res.status == 200){
              console.log("item updated successfully in cart")
              getCartDetails();
              openNotificationWithIcon('success')
            }
          })
    }else{
      console.log("item added")
          axiosInstance.post(`/idol_cart`,{idol_id:idolData?.id, user_id:userId, qty:selectquantity})
          .then((res)=>{
            if(res.status == 208){
              navigate('/login');
            }
            else if(res.status == 200){
              console.log("item added successfully to cart")
              getCartDetails();
              openNotificationWithIcon('success')
            }
    })
    }
  }

  return (
    <>
    {contextHolder}
      <div className="card shadow">
        <div
          className="d-flex align-items-center nav-btn p-3"
          style={{ lineHeight: '35px' }}
        >
          <Button
            type="primary"
            onClick={() => {
              navigate(`/idol-list/${params.idolcat}`);
            }}
          >
            <LeftOutlined /> Back
          </Button>
        </div>
        <div className="row px-md-5 px-3">
          <div className="col-md-4 ">
            <img
              src={idolData?.idol_image}
              alt="sorry Image Error 😞"
              style={{
                width: '250px',
                height: '350px',
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '30px',
              }}
            />
          </div>
          <div style={{ lineHeight: '35px' }} className="col-md-8 text-start">
            <div className="d-flex">
              <h3 className="pe-3">{idolData?.idol_name}</h3>
              <Rate value={4} />
              <span className="ps-3">{'(3 customer reviews)'}</span>
            </div>
            <div className="d-flex">
              <span className="fw-bold">Price :</span>&nbsp;
              {` Rs. ${idolData?.price} /-`}
            </div>
            <div className="card my-3">
              <div className="row p-3">
                <div className="col-md-4">
                  <h5>Dimensions</h5>
                  <Divider style={{ marginTop: '0px', marginBottom: '5px' }} />
                  <div style={{ lineHeight: '25px' }}>
                    <span className="fw-bold">Height :</span>&nbsp;
                    {idolDimensions?.height}
                  </div>
                  <div>
                    <span className="fw-bold">Width :</span>&nbsp;
                    {idolDimensions?.width}
                  </div>
                  <div>
                    <span className="fw-bold">Depth :</span>&nbsp;
                    {idolDimensions?.depth}
                  </div>
                  <div>
                    <span className="fw-bold">Weight :</span>&nbsp;
                    {idolDimensions?.weight}
                  </div>
                  <div>
                    <span className="fw-bold">Metal :</span>&nbsp;
                    {idolDimensions?.metal}
                  </div>
                  <div>
                    <span className="fw-bold">Detail :</span>&nbsp;
                    {idolDimensions?.detail}
                  </div>
                </div>
                <div className="col-md-8">
                  <h5>Description</h5>
                  <Divider style={{ marginTop: '0px', marginBottom: '5px' }} />
                  <p style={{ lineHeight: '25px' }}>{idolData?.idol_desc}</p>
                </div>
              </div>
            </div>
            {idolData.stock ? <div className="d-flex justify-content-end my-2 nav-btn">
            <div className="d-flex ">
              <div className='mx-2'>quantity</div>
            <Select
                defaultValue="1"
                style={{
                  width: 50,
                  borderRadius: 10,
                }}
                options = {options}
                onSelect={(v,o)=>setSelectQuantity(v)}
              />
              </div>
              <Button type="primary mx-2" onClick={addToCart}>Add to Cart</Button>
              <Button type="primary">Buy</Button>
            </div> : <div>Out of Stock</div>}
          </div>
        </div>
      </div>
    </>
  );
}
