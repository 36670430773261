import { LeftOutlined } from '@ant-design/icons';
import { Button, Rate } from 'antd';
import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import card_img_01 from '../assets/card_img_01.jpg';

export default function PoojasDetail() {
  const navigate = useNavigate();
  // const params = useParams();
  // console.log(params, "checking params");
  const location = useLocation();
  console.log(location, "checking location");
  let data = location.state.details;
  console.log(data,"checking data");
  // const details = location.details;
  // console.log(details, "details");

  return (
    <div className="card shadow">
      <div
        className="d-flex align-items-center nav-btn p-3"
        style={{ lineHeight: '35px' }}
      >
        <Button
          type="primary"
          onClick={() => {
            navigate('/poojas-list');
          }}
        >
          <LeftOutlined /> Back
        </Button>
      </div>
      <div className="row px-md-5 px-3">
        <div className="col-md-4 ">
          <img src={data.image} height={370} width={250} />
        </div>
        <div style={{ lineHeight: '35px' }} className="col-md-8 text-start">
          <div className="d-flex">
            <h3 className="pe-3">{data.service_name}</h3>
            <Rate value={4} />
            <span className="ps-3">{'(3 customer reviews)'}</span>
          </div>
          <div className="d-flex">
            <span className="fw-bold">Price Range </span> : Rs {data.economy_price} - Rs {data.standard_price}
          </div>
          <div className="card mt-3">
            {data.poojas_desc}
          </div>
        </div>
      </div>
    </div>
  );
}
