import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";


export const getUser=()=>{
    let userData = null
    const token = localStorage.getItem('token')
    if(token){
        userData = jwtDecode(token);
    }
    return userData
}

export const userLogout=()=>{
    localStorage.removeItem('token')
}