import React from 'react';
import HeaderSec from './HeaderSec';
import { Outlet } from 'react-router-dom';
import FooterSec from './FooterSec';
import { Layout, Flex } from 'antd';

const { Header, Footer, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
};

const contentStyle = {
  textAlign: 'center',
  minHeight: '80vh',
  lineHeight: '120px',
  height: '100%',
};

const footerStyle = {
  textAlign: 'center',
};

const layoutStyle = {
  // overflow: 'auto',
  width: 'calc(100% - 8px)',
  maxWidth: 'calc(100% - 8px)',
  height: '100%',
};

export default function MainLayout() {
  return (
    <div className="container main-layout">
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <HeaderSec />
        </Header>
        <Content style={contentStyle}>
          {' '}
          <Outlet />
        </Content>
        <Footer style={footerStyle}>
          <FooterSec />
        </Footer>
      </Layout>
    </div>
  );
}
